export const defaultLocale = 'el' as const;

export const locales = ['en', 'el'] as const;

export const languageNames = {
  en: 'English',
  el: 'Ελληνικά',
};

export const languageShort = {
  en: 'En',
  el: 'Ελ',
};
