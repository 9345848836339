import 'react-tooltip/dist/react-tooltip.css';

import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { languageNames } from '../../intl/config';
import el from '../../intl/locales/el';
import en from '../../intl/locales/en';
import styles from './Footer.module.scss';

const Footer = (props) => {
  const languageNameRef = useRef(null);
  const [openLanguage, setOpenLanguage] = useState(false);
  const router = useRouter();
  const { locale } = router;
  const t = props.t;
  const [dashboardURLLogin, setDashboardURLLogin] = useState('https://app.whiteclover.io/dashboard/login');
  const [dashboardURLRegister, setDashboardURLRegister] = useState(
    'https://app.whiteclover.io/dashboard/create-account',
  );

  useEffect(() => {
    setDashboardURLLogin(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard/login'
        : `https://app.${window.location.host}/dashboard/login`,
    );

    setDashboardURLRegister(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard/create-account'
        : `https://app.${window.location.host}/dashboard/create-account`,
    );
  }, []);

  return (
    <footer className={styles.FooterSection}>
      <div className={styles.Footer}>
        <div className={styles.footerOrganizer}>
          <div className={`${'didacticH4'} ${styles.list}`}>
            <div>
              <ul>
                <li>
                  <Link href="/#offer-section">{t.footer1}</Link>
                </li>
                <li>
                  <Link href="/#wedding-planner">{t.footer2}</Link>
                </li>
                <li>
                  <Link href="/#guest-app">{t.footer3}</Link>
                </li>
                <li>
                  <Link href="/#your-kind-of-story">{t.footer4}</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <Link href="/partners">{t.footer5}</Link>
                </li>
                <li>
                  <Link href="/pricing">{t.footer6}</Link>
                </li>
                <li>
                  <Link href="/contact">{t.footer7}</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li>
                  <Link href="/about-us">{t.footer8}</Link>
                </li>
                <li>
                  <Link href="/blog">{t.footer9}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.createWedding}>
            <img
              loading="lazy"
              src={`${process.env.CDN_PROVIDER_URL}/newassets/logo_footer.svg`}
              alt="logo for footer"
            />
            <p className={'didacticP'}>{t.footer10}</p>

            <div className={styles.footerButtons}>
              <Link href={dashboardURLRegister}>
                <div className={`${styles.createWeddingButton} didacticH4`}>{t.footer11}</div>
              </Link>
              <Link href={dashboardURLLogin}>
                <div className={`${styles.logInButton} didacticH4`}>{t.footer12}</div>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.socialsCookieSection}>
          <div className={styles.wrapperSocialAndLanguage}>
            <div className={styles.socials}>
              <a href="https://www.instagram.com/whitecloverday/" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src={`${process.env.CDN_PROVIDER_URL}/newassets/icon-instagram.svg`}
                  alt="instagram-whiteclover"
                />
              </a>
              <a href="https://www.facebook.com/whitecloverday" target="_blank" rel="noreferrer">
                <img
                  loading="lazy"
                  src={`${process.env.CDN_PROVIDER_URL}/newassets/icon-facebook.svg`}
                  alt="facebook-whiteclover"
                />
              </a>
              {/*<a href="/" target="_blank" rel="noreferrer">*/}
              {/*  <img src="/newassets/icon-pinterest.svg" alt="pinterest-whiteclover" />*/}
              {/*</a>*/}
            </div>
            <div
              ref={languageNameRef}
              className={`${styles.langButton} ${'didacticH4'}`}
              onClick={() => {
                setOpenLanguage((prev) => !prev);
              }}
            >
              <img loading="lazy" src={`${process.env.CDN_PROVIDER_URL}/newassets/T.svg`} alt="language-change" />
              <div>{languageNames[locale]}</div>
            </div>
            {openLanguage && (
              <div
                style={{
                  top: `${languageNameRef?.current?.offsetTop + 60}px`,
                  left: languageNameRef?.current?.getBoundingClientRect().left,
                }}
                className={styles.languagesList}
              >
                <p
                  className={'didacticP'}
                  onClick={() => {
                    router.push(router.pathname, router.asPath, { locale: 'en' });
                    setOpenLanguage(false);
                  }}
                >
                  {languageNames['en']}
                </p>
                <p
                  className={'didacticP'}
                  onClick={() => {
                    router.push(router.pathname, router.asPath, { locale: 'el' });
                    setOpenLanguage(false);
                  }}
                >
                  {languageNames['el']}
                </p>
              </div>
            )}
          </div>
          <p className={'didacticP'} style={{ marginBottom: '30px', marginTop: '30px' }}>
            <Link href="/terms">{t.footer13}</Link> •{' '}
            <Link href="https://www.iubenda.com/privacy-policy/67891977">{t.footer14}</Link> •{' '}
            <Link href="https://www.iubenda.com/privacy-policy/67891977/cookie-policy">{t.footer15}</Link> •{' '}
            <Link href={'/sitemap.xml'}> • Sitemap</Link>
          </p>
          <p className={'didacticP'}>
            Copyright ©2023{' '}
            <a href="https://thenimaproject.com/" target="_blank" rel="noreferrer">
              Nima Project Ltd
            </a>{' '}
            • Company number 13152926
          </p>
        </div>
      </div>
      <Tooltip id={'footerPartnerTooltip'} />
    </footer>
  );
};

export default Footer;
